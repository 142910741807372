exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-me-index-js": () => import("./../../../src/pages/contact-me/index.js" /* webpackChunkName: "component---src-pages-contact-me-index-js" */),
  "component---src-pages-contact-me-main-section-contact-form-jsx": () => import("./../../../src/pages/contact-me/MainSectionContactForm.jsx" /* webpackChunkName: "component---src-pages-contact-me-main-section-contact-form-jsx" */),
  "component---src-pages-contact-me-main-section-jsx": () => import("./../../../src/pages/contact-me/MainSection.jsx" /* webpackChunkName: "component---src-pages-contact-me-main-section-jsx" */),
  "component---src-pages-contact-me-main-section-snackbar-jsx": () => import("./../../../src/pages/contact-me/MainSectionSnackbar.jsx" /* webpackChunkName: "component---src-pages-contact-me-main-section-snackbar-jsx" */),
  "component---src-pages-design-projects-index-js": () => import("./../../../src/pages/design-projects/index.js" /* webpackChunkName: "component---src-pages-design-projects-index-js" */),
  "component---src-pages-dev-projects-index-js": () => import("./../../../src/pages/dev-projects/index.js" /* webpackChunkName: "component---src-pages-dev-projects-index-js" */),
  "component---src-pages-homage-page-projects-section-project-card-jsx": () => import("./../../../src/pages/homage-page/projects-section/ProjectCard.jsx" /* webpackChunkName: "component---src-pages-homage-page-projects-section-project-card-jsx" */),
  "component---src-pages-homage-page-projects-section-project-section-jsx": () => import("./../../../src/pages/homage-page/projects-section/ProjectSection.jsx" /* webpackChunkName: "component---src-pages-homage-page-projects-section-project-section-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */)
}

